window.module 'Booksession', ->
  @module 'PayWithStripe', ->
    @init =->
      $('button.pay-with-stripe').click (e) ->
        e.preventDefault()

        self = $(@)
        stripe = Stripe(self.data('stripe'), stripeAccount: self.data('stripeAccount'))

        $.post self.data('payUrl'), { success_url: self.data('successUrl'), cancel_url: self.data('cancelUrl')}, (data) ->
          stripe.redirectToCheckout(sessionId: data.checkout_session_id).then (result) ->


$(document).on "turbo:load", ->
  Booksession.PayWithStripe.init() if $('button.pay-with-stripe').length
