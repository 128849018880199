window.module 'Booksession', ->
  @module 'Settings', ->
    @module 'PricingPackages', ->
      @module 'Form', ->
        @init = ->
          $('a.add_fields').on 'click', ->
            setTimeout (->
              Booksession.Duration.init()
            ),

$(document).on "turbo:load", ->
  Booksession.Settings.PricingPackages.Form.init() if $('body.pricing-packages').length
