document.addEventListener('turbo:load', () => {
  if ($('#client_is_business').length) {
    $('#client_is_business').change(function(e) {

      if ($('#client_is_business').is(':checked'))
        $('#client_business_name').closest('.col-span-6').removeClass('hidden')
      else
        $('#client_business_name').val('').closest('.col-span-6').addClass('hidden')
    })
  }
})
