import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  offsetSelectFilter() {
    let triggerValue = this.element.value
    let targetList = document.getElementById('workflows_action_offset_type')

    targetList.options.forEach((option) => {
      if (option.value === triggerValue) {
        option.hidden = false
        option.disabled = false
      } else {
        option.hidden = true
        option.disabled = true
      }
    })
  }
}
