window.module 'Booksession', ->
  @module 'Template', ->
    @module 'ChildField', ->
      @init = ->
        initAddItem()
        initRemoveItem()
        initSortable()
        initUpdateItem()

      initAddItem =->
        $(document.body).on 'click', 'a.add-new-child-item', (e) ->
          e.preventDefault()

          form_group = $(@).closest('.form-group')

          if form_group.find('input[value=dropdown]').length
            type = 'dropdown_item'
          else
            type = 'checkbox'

          added_elem = $(@).closest('.settings').find('ul').append("
            <li class='col-span-4 sm:col-span-4'>
              <div class='mt-2 rounded-md shadow-sm flex'>
                <span class='sortable-handle bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm'>
                  <i class='fa fa-bars reorder'></i>
                </span>
                <input type='text' name='title' class='item-title form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5', placeholder='Please enter an item title'>
                <input type='hidden' class='position-number' name='position' >
                <input value='#{type}' type='hidden' name='field_type'>
                <input type='hidden' value='false' name='_destroy' class='child-form-field-destroy'>
                <span class='inline-flex items-center px-3 rounded-r-md rounded-l-md border border-gray-300 bg-gray-50 text-gray-500 text-sm ml-4'>
                  <a href='#' class='remove-child-form-field'>
                    <i class='fa fa-trash text-master hint-text'></i>
                  </a>
                </span>
              </div>
            </li>
          ").find('li:last')

          renameFields added_elem
          updatePosition added_elem.closest('ul')
          resetPreview added_elem


      renameFields = (elem) ->
        name_template =  elem.closest('.form-group').find('input.position').prop('name')
        id = Math.floor(Math.random() * Date.now())

        elem.find('input').each ->
          $(@).prop('name', name_template.replace(/\[position\]/, "[child_template_fields_attributes][#{ id }][#{ $(@).prop('name') }]"))
          $(@).prop 'id', $(@).prop('name').replace(/\]\[|\[|\]/g, '_').slice(0,-1)

       updatePosition = (elem) ->
         elem.find('li').each ->
           $(@).find('input.position-number').val(elem.find('li').index($(@)) + 1)

       initRemoveItem =->
         $(document.body).on 'click', 'a.remove-child-form-field', (e) ->
           e.preventDefault()
           elem = $(@)
           dropdown = elem.closest('ul')
           destroy_field = elem.closest('li').find('input.child-form-field-destroy').val('true').clone()

           if confirm("Deleting this item will PERMANENTLY delete it from the system and it won't be available anymore.")
             elem.closest('li').remove()
             $('form').prepend destroy_field
             updatePosition dropdown
             resetPreview dropdown

       initSortable = ->
         $('ul.child-items-sortable').each ->
           unless $(@).data('ui-sortable')
             $(@).sortable(
               connectWith: 'li'
               handle: 'span.sortable-handle'
               placeholder: 'ui-state-highlight'
               update: (event, ui) ->
                 updatePosition $(@).closest('ul')
                 resetPreview $(@).closest('ul')
              )

       initUpdateItem =->
         $(document.body).on 'keyup change', 'input.item-title', (e) ->
           resetPreview $(@).closest('ul')

       resetPreview = (elem) ->
         form_group = elem.closest('.form-group')

         #update dropdown preview
         if form_group.find('input[value=dropdown]').length
           options = '<option></option>'
           elem.find('li input.item-title').each (index) ->
             options += "<option value='#{ $(@).val() }'>#{ $(@).val() }</option>"

             form_group.find('select').html(options)
         #update checkboxes or radio buttons
         else
           resetCheckboxes form_group

      resetCheckboxes = (form_group) ->
        checkboxes_list = form_group.find('.preview').html("<div class='form_field_checkboxes_group'></div>")
        options_list    = form_group.find('ul.child-items-sortable li input.item-title')


        options_list.each (index) ->
          id = Math.random().toString(36).substr(2, 19)
          label_name = $(@).val()

          checkboxes_list.append "
            <div class='flex items-start'>
              <div class='h-5 flex items-center'>
                <input type='checkbox' value='#{label_name}' id='#{id}' class='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'>
              </div>
              <div class='ml-3 text-sm'>
                <label for='#{id}' class='font-medium text-gray-700'>#{label_name}</label>
              </div>
            </div>
            "
