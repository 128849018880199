import { Controller } from '@hotwired/stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
  canvas = null

  connect() {
    this.canvas = this.element
    this.canvas.height = this.canvas.offsetHeight
    this.canvas.width = this.canvas.offsetWidth

    this.resizeCanvas()

    const signaturePad = new SignaturePad(this.canvas)
    const canvasParent = $(this.canvas).closest('.form-group, .canvas')
    const signatureInput = canvasParent.find('.signature_pad_input')
    const signatureInputPonits = canvasParent.find('.signature_pad_points')

    if (signatureInput.val() !== '') {
      signaturePad.fromDataURL(canvasParent.find('.signature_pad_input').val());
    }

    canvasParent.find('.signature_pad_clear').click(function(e) {
      e.preventDefault();
      signaturePad.clear();
    });

    signaturePad.addEventListener("endStroke", () => {
      let points = 0
      signaturePad.toData().map(function(elem){
        points = points + elem.points.length
      })

      signatureInputPonits.val(points)
    });

    $('.signature_pad_clear').click(function() {
      signatureInputPonits.val(0)
    })

    $(this.canvas).closest('form').submit(function(e) {
      if (!signaturePad.isEmpty()) {
        signatureInput.val(signaturePad.toDataURL())
      }
    })
  }

  disconnect() {
    window.onresize = null
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    this.canvas.width = this.canvas.offsetWidth * ratio
    this.canvas.height = this.canvas.offsetHeight * ratio
    this.canvas.getContext('2d').scale(ratio, ratio)
  }
}
