window.module 'Booksession', ->
  @module 'Users', ->
    @module 'SubscriptionConfirm', ->
      @init = ->
        setTimeout ->
          checkSubscriptionStatus()
        , 2000

        setInterval ->
          checkSubscriptionStatus()
        , 3000

      checkSubscriptionStatus =->
        $.get '/users/subscription_status.json', (data) ->
          window.location.href = '/' if data.subcription_status == 'active' || data.subcription_status == 'trialing'

$(document).on "turbo:load", ->
  Booksession.Users.SubscriptionConfirm.init() if $('body#users-subscription-confirm').length
