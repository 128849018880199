window.module 'Booksession', ->
  @module 'EmailTemplates', ->
    @module 'Form', ->
      @init = ->
        $('a.purge-attachment').on 'click', (e) ->
          e.preventDefault()

          if confirm('Are you sure?')
            $(@).closest('li').find('input[type="checkbox"]').prop 'checked', true
            $(@).closest('li').addClass('hidden')

$(document).on "turbo:load", ->
  Booksession.EmailTemplates.Form.init() if $('body.email-templates').length
