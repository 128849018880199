import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  hide(e) {
    // Set the "accountLimitReached" cookie to false and hide the notification
    const date = new Date();
    date.setDate(date.getDate() + 7);

    // Set the "accountLimitReached" cookie with an expiration date 7 days from now
    document.cookie = `${this.cookieName}=true; expires=${date.toUTCString()}; path=/;`;
    this.element.remove()
  }

  private

  get cookieName() {
    // Read the "data-cookie-name" attribute to get the cookie name
    return this.element.dataset.cookieName
  }
}
