import { Controller } from "@hotwired/stimulus"
import { put } from '@rails/request.js'

export default class extends Controller {
  static values = { fieldName: String }

  connect() {
    // Initial setup or any necessary configuration
  }

  handleChange(event) {
    const fieldName = event.target.name || this.fieldNameValue // Get the field name from the input or controller value
    const url = this.element.dataset.url // Get the URL from the data-url attribute
    const value = event.target.value // Get the current value of the field

    this.sendPutRequest(url, fieldName, value)
  }

  sendPutRequest(url, fieldName, value) {
    put(url, {
      body: JSON.stringify({
        [fieldName]: value // Send the field name and value as JSON
      }),
      responseKind: "turbo-stream"
    })
  }
}
