import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['global', 'specific', 'day', 'date', 'repeat']

  connect() {
    setTimeout(() => {
      if (document.getElementById('day_of_week').checked) {
        this.showSpecific()
        this.showDay()
      } else {
        this.showGlobal()
        this.showDate()
      }
      this.repeatTargets.hidden = document.getElementById('repeat_forever').checked
    }, 0)
  }

  showGlobal() {
    this.toggleVisibility(this.globalTargets, false)
    this.toggleVisibility(this.specificTargets, true)
  }

  showSpecific() {
    this.toggleVisibility(this.globalTargets, true)
    this.toggleVisibility(this.specificTargets, false)
  }

  showDate() {
    this.toggleVisibility(this.dateTargets, false)
    this.toggleVisibility(this.dayTargets, true)
  }

  showDay() {
    this.toggleVisibility(this.dateTargets, true)
    this.toggleVisibility(this.dayTargets, false)
  }

  toggleRepeatUntil(event) {
    this.toggleVisibility(this.repeatTargets, (event.target.checked))
  }

  toggleVisibility(targets, hidden) {
    targets.forEach(el => {
      el.hidden = hidden
    });
  }

}
