window.module 'Booksession', ->
  @module 'External', ->
    @module 'Bookings', ->
      @module 'Show', ->
        @init = ->
          seconds = gon.seconds
          display = document.querySelector('#time')
          startTimer seconds, display

        startTimer = (duration, display) ->
          timer = duration
          minutes = undefined
          seconds = undefined
          myTimer = setInterval (->
            minutes = parseInt(timer / 60, 10)
            seconds = parseInt(timer % 60, 10)
            minutes = if minutes < 10 then '0' + minutes else minutes
            seconds = if seconds < 10 then '0' + seconds else seconds
            display.textContent = minutes + ':' + seconds
            if --timer < 0
              clearInterval(myTimer)
              window.location.href = gon.redirect_to
          ), 1000


$(document).on "turbo:load", ->
  Booksession.External.Bookings.Show.init() if $('#bookings-show.external').length
