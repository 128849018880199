window.module 'Booksession', ->
  @module 'External', ->
    @module 'Bookings', ->
      @module 'Calendar', ->
        @init = ->
          $(document).on 'click', 'a.calendar-day', (e) ->
            $('a.calendar-day').removeClass('bg-indigo-200 border-indigo-600')
            $(@).addClass 'bg-indigo-200 border-indigo-600'
            $('button[type=submit]').addClass 'hidden'


$(document).on "turbo:load", ->
  Booksession.External.Bookings.Calendar.init() if $('body#bookings-timeslots, body#bookings-available-timeslots').length
