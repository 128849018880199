import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['image'];

  connect() {
    const options = {
      rootMargin: '0px',
      threshold: 0.1
    };

    this.observer = new IntersectionObserver(this.loadImage.bind(this), options);
    this.observer.observe(this.imageTarget);
  }

  loadImage(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const image = entry.target;
        image.src = image.dataset.src;
        this.observer.unobserve(image);
      }
    });
  }
}
