import Lightbox from 'stimulus-lightbox'

export default class extends Lightbox {
  connect() {
    super.connect()
    // Get the lightgallery instance
    this.lightGallery

    // Default options for every lightboxes.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {
      download: false,
      selector: '.photo-popup'
      // Your default options here
    }
  }
}
