import { Controller } from '@hotwired/stimulus'
import flatpickr from "flatpickr"
import "flatpickr/dist/l10n"

flatpickr.localize(flatpickr.l10ns.default) // Load the default locale
flatpickr.l10ns.default.firstDayOfWeek = 1 // Set the first day of the week to Monday

export default class extends Controller {
  connect() {
    this.initialiseFlatpickr()
  }

  disconnect() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy()
      this.flatpickrInstance = null
    }
  }

  initialiseFlatpickr() {
    if (this.element.dataset.type === 'date') {
      this.initDatepicker()
    } else if (this.element.dataset.type === 'time') {
      this.initTimepicker()
    } else if (this.element.dataset.type === 'datetime') {
      this.initDatetimepicker()
    }
  }

  initDatepicker() {
    this.flatpickrInstance = flatpickr(this.element, {
      locale: flatpickr.l10ns.default,
      dateFormat: "d-m-Y",
      minDate: this.element.dataset.minDate || null,
      disable: this.element.dataset.disabledDays ? JSON.parse(this.element.dataset.disabledDays) : [],
      onChange: (selectedDates, dateStr, instance) => {
        this.element.dispatchEvent(new CustomEvent('dateChanged', { detail: { selectedDates, dateStr, instance } }))
      }
    })
  }

  initTimepicker() {
    this.flatpickrInstance = flatpickr(this.element, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      defaultHour: 9,
      time_24hr: true,
      onChange: (selectedDates, dateStr, instance) => {
        this.element.dispatchEvent(new CustomEvent('timeChanged', { detail: { selectedDates, dateStr, instance } }))
      }
    })
  }

  initDatetimepicker() {
    this.flatpickrInstance = flatpickr(this.element, {
      locale: flatpickr.l10ns.default,
      dateFormat: "d-m-Y H:i",
      enableTime: true,
      onChange: (selectedDates, dateStr, instance) => {
        this.element.dispatchEvent(new CustomEvent('dateTimeChanged', { detail: { selectedDates, dateStr, instance } }))
      }
    })
  }
}
