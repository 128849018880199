// assuming the file is saved in your stimulus controllers directory as time_of_day_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.textContent = this.timeOfDay()
  }

  timeOfDay() {
    const date = new Date()
    const hour = date.getHours()

    if (hour >= 0 && hour < 6) {
      return "night"
    } else if (hour >= 6 && hour < 12) {
      return "morning"
    } else if (hour >= 12 && hour < 17) {
      return "afternoon"
    } else {
      return "evening"
    }
  }
}
