window.module 'Booksession', ->
  @module 'External', ->
    @module 'Bookings', ->
      @module 'Timeslots', ->
        @init = ->
          $(document).on 'click', 'label.calendar-timeslot', (e) ->
            self = $(@)
            $('label.calendar-timeslot').removeClass('border-indigo-500 bg-indigo-500 text-white').addClass('text-gray-500 bg-white hover:bg-gray-50')
            self.addClass('border-indigo-500 bg-indigo-500 text-white').removeClass('text-gray-500 bg-white hover:bg-gray-50')
            $('button[type=submit]').toggleClass 'hidden', !$('input[type=radio]:checked').length

$(document).on "turbo:load", ->
  Booksession.External.Bookings.Timeslots.init() if $('body#bookings-timeslots, body#bookings-available-timeslots').length
