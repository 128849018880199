window.module 'Booksession', ->
  @module 'Template', ->
    @module 'Form', ->
      @init = ->
        $('.booking-template-type input[type=radio]').change ->
          # reset styles
          $('.booking-template-type label').addClass('border-gray-200').removeClass('bg-indigo-50 border-indigo-200 z-10')
          $('.booking-template-type span.label').addClass('text-gray-900').removeClass('text-indigo-900')
          $('.booking-template-type span.description').addClass('text-gray-500').removeClass('text-indigo-700')

          # set active style
          $(@).closest('label').removeClass('border-gray-200').addClass('bg-indigo-50 border-indigo-200 z-10')
          $(@).closest('label').find('span.label').removeClass('text-gray-900').addClass('text-indigo-900')
          $(@).closest('label').find('span.description').removeClass('text-gray-500').addClass('text-indigo-700')

          $('.hide-if-tfp').toggleClass 'hidden', @value == 'true'

        $('.booking-template-type input[type=radio]:checked').trigger 'change'  


$(document).on "turbo:load", ->
  Booksession.Template.Form.init() if $('body.booking-templates, body.questionnaire-templates').find('form').length
