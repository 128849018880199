import Rails from "@rails/ujs"
import jquery from 'jquery'
import * as ActiveStorage from "@rails/activestorage"
import _ from 'lodash'

import "jquery-ui-dist/jquery-ui"

import { Turbo } from "@hotwired/turbo-rails"
import TurboPower from 'turbo_power'
import introJS from 'intro.js/intro.js'
import '@nathanvda/cocoon'

import 'offline-js/offline.js'
import 'jarallax/dist/jarallax.js'

import Alpine from 'alpinejs'

window.$ = window.jQuery = jquery
window.Alpine = Alpine
window.introJS = introJS
window._ = _

import "chartkick/chart.js"

// // Tailwind CSS
import "../stylesheets/application.scss"
// // Stimulus controllers
import "../controllers"
import '../src'

import LocalTime from "local-time"
LocalTime.start()

//Turbo Drive is disabled by default
Turbo.session.drive = false

document.addEventListener('turbo:load', () => {
  Chartkick.eachChart((chart) => {
    chart.redraw()
  })

  if (typeof window.dataLayer === 'function') {
    window.dataLayer.push({
      'event': 'pageview',
      'virtualUrl': window.location.pathname
    });
  }

  Alpine.start()
})

TurboPower.initialize(Turbo.StreamActions)
Rails.start()
ActiveStorage.start()
