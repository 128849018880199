import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import { Controller } from '@hotwired/stimulus'

window.range = function(from, to, step) {
  return [...Array(Math.floor((to - from) / step) + 1)].map(function(_, i) {
    return from + i * step + '';
  });
}

const DEFAULT_FROALA_OPTIONS = {
  key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
  attribution: false,
  charCounterCount: false,
  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
  videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
  videoEditButtons: ['videoDisplay', 'videoRemove'],
  imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
  heightMin: 200,
  pastePlain: true,
  linkAlwaysBlank: true,
  imageUploadUrl : false,
  pluginsEnabled: ['align', 'colors', 'draggable', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url', 'video'],
  imageUploadToS3: gon.froala_s3_settings,
  fontSize: ['8', '9', '10', '11', '12', '13', '14', '15'].concat(window.range(16, 96, 2))
}

export default class extends Controller {
  connect() {
    let toolbarButtons = ['paragraphFormat', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'fontSize', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'align', 'insertLink', 'insertImage', 'insertVideo', 'insertTable']
    let froala_options

    if (this.element.classList.contains('full-page'))
      froala_options = $.extend({}, DEFAULT_FROALA_OPTIONS, { fullPage: true })
    else
      froala_options = DEFAULT_FROALA_OPTIONS

    if (this.element.classList.contains('booking-variables') && !toolbarButtons.includes('insert_variable')) {
      FroalaEditor.DefineIconTemplate('text_design', '<span style="text-align: center;">[NAME]</span>');
      FroalaEditor.DefineIcon('insert_variable', {
        NAME: 'Insert Variable',
        template: 'text_design'
      });
      FroalaEditor.RegisterCommand('insert_variable', {
        title: 'Insert Variable',
        type: 'dropdown',
        focus: false,
        undo: true,
        more_btn: true,
        refreshAfterCallback: true,
        options: {
          ' %BUSINESS_NAME% ': 'Business Name',
          ' %BUSINESS_EMAIL% ': 'Business Email',
          ' %BUSINESS_PHONE% ': 'Business Phone',
          ' %FINAL_PRICE% ': 'Final Price',
          ' %DEPOSIT% ': 'Deposit'
        },
        callback: function(cmd, val) {
          this.html.insert(val);
        }
      }, toolbarButtons.push('insert_variable'));
    } else if (this.element.classList.contains('client-email-variables') && !toolbarButtons.includes('insert_variable')) {
      FroalaEditor.DefineIconTemplate('text_design', '<span style="text-align: center;">[NAME]</span>');
      FroalaEditor.DefineIcon('insert_variable', {
        NAME: 'Insert Variable',
        template: 'text_design'
      });
      FroalaEditor.RegisterCommand('insert_variable', {
        title: 'Insert Variable',
        type: 'dropdown',
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        options: {
          ' %BUSINESS_NAME% ': 'Business Name',
          ' %BUSINESS_EMAIL% ': 'Business Email',
          ' %BUSINESS_PHONE% ': 'Business Phone',
          ' %CLIENT_FIRST_NAME% ': 'Client First Name',
          ' %CLIENT_LAST_NAME% ': 'Client Last Name',
          ' %CLIENT_FULL_NAME% ': 'Client Full Name',
          ' %CLIENT_EMAIL% ': 'Client Email',
          ' %CLIENT_PHONE% ': 'Client Phone',
          ' %CLIENT_ADDRESS% ': 'Client Address',
          ' %EVENT_DATE_AND_TIME% ': 'Event Date & Time',
          ' %EVENT_TYPE% ': 'Photosession Type',
          ' %PRICING_PLAN% ': 'Selected Pricing Plan',
          ' %ADDITIONAL_SELECTED_PRODUCTS% ': 'Selected Additional Products',
          ' %PAID_DEPOSIT% ': 'Paid Deposit',
          ' %REMAIN_BALANCE% ': 'Remain Balance',
          ' %BUSINESS_EMAIL_SIGNATURE% ': 'Business Email Signature',
          ' %LINK_TO_BOOKING% ': 'Click here to see booking details'
        },
        callback: function(cmd, val) {
          this.html.insert(val);
        }
      }, toolbarButtons.push('insert_variable'));
    } else if (this.element.classList.contains('questionnaire-variables') && !toolbarButtons.includes('insert_variable')) {
      FroalaEditor.DefineIconTemplate('text_design', '<span style="text-align: center;">[NAME]</span>');
      FroalaEditor.DefineIcon('insert_variable', {
        NAME: 'Insert Variable',
        template: 'text_design'
      });
      FroalaEditor.RegisterCommand('insert_variable', {
        title: 'Insert Variable',
        type: 'dropdown',
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        options: {
          ' %BUSINESS_NAME% ': 'Business Name',
          ' %BUSINESS_EMAIL% ': 'Business Email',
          ' %BUSINESS_PHONE% ': 'Business Phone'
        },
        callback: function(cmd, val) {
          this.html.insert(val);
        }
      }, toolbarButtons.push('insert_variable'));
    }

    froala_options = $.extend({}, froala_options, {
      toolbarButtons: toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtonsSM: toolbarButtons,
      toolbarButtonsXS: toolbarButtons,
      useClasses: this.element.classList.contains('froala-inline')
    });

    new FroalaEditor(this.element, froala_options)
  }
}
