window.module 'Booksession', ->
  @module 'Bookings', ->
    @module 'PricingPlan', ->
      @init = ->
        $('input[type="radio"].pricing-plan').change ->
          resetCssClasses()
          $(@).closest('.pricing-div').addClass('border-indigo-500 bg-blue-50')
          $(@).parent().find('svg').addClass('text-indigo-600')
          $(@).parent().find('h2').addClass('text-indigo-500')

        $('input[type="radio"].pricing-plan:checked').trigger 'change'  

      resetCssClasses =->
        $('input[type="radio"].pricing-plan').each ->
          $(@).closest('.pricing-div').removeClass('border-indigo-500  bg-blue-50')
          $(@).parent().find('svg').removeClass('text-indigo-600')
          $(@).parent().find('h2').removeClass('text-indigo-500')

$(document).on "turbo:load touch", ->
  Booksession.Bookings.PricingPlan.init() if $('input[type="radio"].pricing-plan').length
