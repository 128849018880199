import { Controller } from '@hotwired/stimulus'
import Choices from "choices.js"

export default class extends Controller {
  connect() {
    new Choices(this.element, {
      allowHTML: true,
      delimiter: ',',
      editItems: true,
      removeItemButton: true,
      duplicateItemsAllowed: false,
      placeholderValue: 'Add Email Address',
      addItemFilter: function(value) {
        if (!value) {
          return false;
        }

        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const expression = new RegExp(regex.source, 'i');
        return expression.test(value);
      },
    });
  }
}
