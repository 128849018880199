window.module 'Booksession', ->
  @module 'Clients', ->
    @module 'Import', ->
      @init =->
        $('form#import_client').submit (e) ->
          e.preventDefault()

          self = $(@)
          formData = new FormData()
          formData.append 0, self.find('[type=file]')[0].files[0]

          unless self.find('[type=file]')[0].files[0]
            alert("You have not selected any CSV file.")
            return

          $.ajax
            method: 'POST'
            url: '/clients/process_import.js'
            data: formData
            processData: false
            contentType: false
            success: (data) ->
              $('#import_step_1').hide()
              $('#import_step_2').removeClass('hidden').html data
              self.off()

$(document).on "turbo:load", ->
  Booksession.Clients.Import.init() if $('body form#import_client').length
