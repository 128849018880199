window.module 'Booksession', ->
  @module 'PayWithAfterpay', ->
    @init =->
      $('a.pay-with-afterpay, a.pay-with-square').click (e) ->
        e.preventDefault()

        $(@).closest('p').next('form').removeClass 'hidden'
        $(@).addClass 'hidden'


$(document).on "turbo:load", ->
  Booksession.PayWithAfterpay.init() if $('a.pay-with-afterpay, a.pay-with-square').length
