window.module 'Booksession', ->
  @module 'Clients', ->
    @module 'SendEmail', ->
      @init = ->
        $('#email_log_template_id').change ->
          text = $(@).find('option:selected').data('html')
          $("#email_log_body")[0]["data-froala.editor"].html.set "#{if text == undefined then '' else text} <p>---</p> #{gon.email_signature}"

$(document).on "turbo:load", ->
  Booksession.Clients.SendEmail.init() if $('body#clients-send-email, body#clients-sent-email').length
