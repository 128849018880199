import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-fetch-response", (event) => { this.close(event) })
  }

  close(event) {
    if (event.detail.fetchResponse.response.ok) {
      if (document.getElementById('modal'))
        document.getElementById('modal').childNodes.forEach((node) => node.remove())
      if (document.getElementById('slide-over'))
        document.getElementById('slide-over').querySelector('button:first-child').click()
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-response", this.close.bind(this))
  }
}
