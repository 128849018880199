import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { fieldName: String }

  connect() {
    // Initial setup or any necessary configuration
  }

  handleChange(event) {
    event.preventDefault()

    const url = this.element.dataset.url // Get the URL from the data-url attribute
    this.sendPutRequest(url)
  }

  sendPutRequest(url) {
    get(url, {
      responseKind: "turbo-stream"
    })
  }
}
