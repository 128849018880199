window.module 'Booksession', ->
  @module 'Invoices', ->
    @module 'Form', ->
      @init = ->
        calulcationPreview()

        $(document).on 'keyup', 'input.qty, input.price, #invoice_discount', (e) ->
          calulcationPreview()

        $('#invoice_discount_type').change ->
          calulcationPreview()

        $(document).on 'cocoon:after-remove', (e) ->
          calulcationPreview()

      isBlank = (value) ->
        _.isEmpty(value) and !_.isNumber(value) or _.isNaN(value)

      formatCurrency = (value) ->
        value.toLocaleString('en-US', { style: 'currency', currency: gon.currency })


      calulcationPreview =->
        subtotal = 0
        $('.nested-fields-items:visible').each ->
          subtotal = subtotal + ( _.toNumber($(@).find('input.qty').val()) * _.toNumber($(@).find('input.price').val()) )

        $('div.subotal .value').text formatCurrency(subtotal)

        discount = $('#invoice_discount').val()

        if isBlank(discount)
          $('div.discount').addClass 'hidden'

          $('div.subtotal .value').text formatCurrency(subtotal)
          $('div.total .value b').text formatCurrency(subtotal)

        else
          $('div.discount').removeClass('hidden')
          if $('#invoice_discount_type').val() == 'dollars'
            $('div.discount .value').text formatCurrency(discount)


            if subtotal - discount > 0
              $('div.total .value b').text formatCurrency(subtotal - discount)
            else
              $('div.total .value b').text formatCurrency(0)

          else
            $('div.discount').find('.value').text "#{discount}%"

            calculated_discount = subtotal * discount / 100.0
            if subtotal - calculated_discount > 0
              $('div.total .value b').text formatCurrency(subtotal - calculated_discount)

            else
              $('div.total .value b').text formatCurrency(0)


$(document).on "turbo:load", ->
  Booksession.Invoices.Form.init() if $('body.invoices').length
