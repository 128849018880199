window.module = (names, fn) ->
  name = undefined
  space = undefined
  if typeof names == 'string'
    names = names.split('.')
  space = @[name = names.shift()] or (@[name] = {})
  space.module or (space.module = @module)
  if names.length
    space.module names, fn
  else
    fn.call space
