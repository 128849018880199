import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener("resize", this.setHeight.bind(this))
    window.addEventListener("scroll", this.setHeight.bind(this))
    this.setHeight()
  }

  setHeight() {
    this.element.style.height = `${this.pageHeight()}px`
  }

  disconnect() {
    window.removeEventListener("resize", this.setHeight.bind(this))
    window.removeEventListener("scroll", this.setHeight.bind(this))
  }

  pageHeight() {
    return Math.max(
        document.documentElement.scrollHeight,
        document.documentElement.clientHeight
    )
  }
}
