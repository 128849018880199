import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static geocomplete = null

  connect() {
    if (typeof (google) != "undefined"){
      this.geocomplete = this.element
      this.initializeMap()
    }
  }

  initializeMap() {
    this.autocomplete()
  }

  autocomplete() {
    if (this._autocomplete == undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(this.geocomplete)
      this._autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
    }
    return this._autocomplete
  }
}
