window.module 'Booksession', ->
  @module 'Workflows', ->
    @module 'Form', ->
      @init =->
        $(document).on 'change', 'select.action-trigger', (e) ->
          offsetSelectFilter()

        initChangeEmailTemplate()

      initChangeEmailTemplate =->
        $(document).on 'change', 'select.email-template', (e) ->
          id =  $(@).val()
          self = $(@)
          $.get "/api/email_templates/#{id}", (data) ->
            self.closest('turbo-frame').find('input.subject').val data.subject
            self.closest('turbo-frame').find("textarea.body")[0]["data-froala.editor"].html.set data.body

      offsetSelectFilter =->
        $('select.offset-type').each ->
          trigger_value = $(@).closest('.grid').find('select.action-trigger').val()
          $(@).find('option').each ->
            if $(@).data('trigger') != trigger_value
              $(@).hide().attr('disabled', true)
            else
              $(@).show().attr('disabled', false)
          unless $(@).find('option:selected').is(':visible')
            $(@).find('option:not(:disabled):first').prop('selected', true)


$(document).on "turbo:load", ->
  Booksession.Workflows.Form.init() if $('body.workflows-templates').length
