import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.updateSliderDisplay()
  }

  handleSlider() {
    this.updateSliderDisplay()
  }

  updateSliderDisplay() {
    const sliderValue = this.element.value
    const sliderName = this.element.name
    const sliderDisplay = document.getElementById(`${sliderName}-value`)
    sliderDisplay.innerHTML = `${sliderValue}%`
  }
}