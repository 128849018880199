$(document).on 'turbo:load changeAlbum', ->
  !((window) ->
    $q = (q, res) ->
      if document.querySelectorAll
        res = document.querySelectorAll(q)
      else
        d = document
        a = d.styleSheets[0] or d.createStyleSheet()
        a.addRule q, 'f:b'
        l = d.all
        b = 0
        c = []
        f = l.length
        while b < f
          l[b].currentStyle.f and c.push(l[b])
          b++
        a.removeRule 0
        res = c
      res

    addEventListener = (evt, fn) ->
      if window.addEventListener then window.addEventListener(evt, fn, false) else if window.attachEvent then @attachEvent('on' + evt, fn) else (@['on' + evt] = fn)
      return

    _has = (obj, key) ->
      Object::hasOwnProperty.call obj, key

    images = new Array
    query = $q('img.lazyload')

    processScroll = ->
      i = 0
      while i < images.length
        if elementInViewport(images[i])
          loadImage images[i], ->
            images.splice i, i
            return
        i++
      return

    # Array.prototype.slice.call is not callable under our lovely IE8

    loadImage = (el, fn) ->
      img = new Image
      src = el.getAttribute('data-src')

      img.onload = ->
        if ! !el.parent
          el.parent.replaceChild img, el
        else
          el.src = src
        if fn then fn() else null
        return

      img.src = src
      return

    elementInViewport = (el) ->
      rect = el.getBoundingClientRect()
      rect.top >= 0 and rect.left >= 0 and rect.top <= (window.innerHeight or document.documentElement.clientHeight)

    i = 0
    while i < query.length
      images.push query[i]
      i++
    processScroll()
    addEventListener 'scroll', processScroll
    return
  )(this)
