import {post} from "@rails/request.js"

window.module 'Booksession', ->
  @module 'Tours', ->
    @module 'Dashboard', ->
      @init = ->
        steps = [
          {
            title: 'Welcome to Your Dashboard Tour!'
            intro: 'Welcome to the heart of your photography management hub! <br><br>
                    Let\'s take a guided tour through the powerful features and tools available on your dashboard:'
          },
          {
            element: document.querySelector('.bookings-last-7-days')
            intro: 'Track your booking activity for the past week: <br> <br>
             - <strong>New Completed Bookings:</strong> Track successful appointments.
             <br>
             - <strong>Incomplete Bookings:</strong> Monitor pending appointments.
            '
          },
          {
            element: document.querySelector('.pie-chart')
            intro: 'Your bookings for the past week grouped by type.'
          },
          {
            element: document.querySelector('#incomplete-bookings')
            intro: 'View a list of last 10 incomplete bookings here. lick on a row to see details',
            position: 'right'
          },
          {
            element: document.querySelector('#recent-bookings')
            intro: 'View a list of last 10 complete bookings here. Click on a row to see details.',
            position: 'left'
          },
          {
            element: document.querySelector('#not-paid-invoices')
            intro: 'View a list of last 10 outstanding invoices. Click on a row to see details.',
            position: 'right'
          },
          {
            element: document.querySelector('#pending-bookings')
            intro: 'View a list of last 10 pending bookings. Click on a row to see details.',
            position: 'left'
          },
          {
            element: document.querySelector('.chat-with-us')
            intro: 'If you have a question or need help, click here to chat with us. We are here to help you!'
          },
          {
            element: document.querySelector('.help-center')
            intro: 'Click here to access our help center. You can find answers to common questions and get help with your account.'
          },
          {
            element: document.querySelector('.profile-icon')
            intro: 'Your profile details are here. Click it to manage subscription or change your name, email address, timezone, photo, password, etc.'
          },
          {
            title: 'End of the Tour'
            intro: 'You have completed the tour of your dashboard. <br> <br> If you have any questions, please feel free to reach out to us. <br> <br> Enjoy your photography management hub!'
          }
        ]

        introJS().setOptions(steps: steps).onexit( ->
          # make ajax call to update user's introjs status
          #          post('/api/users/update_tour', {
          #            responseKind: "json"
          #            body:
          #              tour: 'dashboard'
          #          })

        ).start()

$(document).on "turbo:load", ->
  Booksession.Tours.Dashboard.init() if $('body#dashboard-show').length && gon && gon.tour == 'dashboard'
