// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus'
import { Modal, Tabs, Alert } from "tailwindcss-stimulus-components"
import Clipboard from 'stimulus-clipboard'
import Sortable from '@stimulus-components/sortable'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import AccordionController from '@kanety/stimulus-accordion'
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()
const controllers = import.meta.glob("./**/*_controller.js", { eager: true });
registerControllers(application, controllers)

application.register('modal', Modal)
application.register('alert', Alert)
application.register('tabs', Tabs)
application.register('clipboard', Clipboard)
application.register('sortable', Sortable)
application.register('textarea-autogrow', TextareaAutogrow)
application.register('accordion', AccordionController)
