import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  connect() {
    Turbo.setConfirmMethod((payload) => {
      let dialog = document.getElementById('turbo-confirm')
      let turbo_close = dialog.querySelector('.cancel')

      let data = JSON.parse(payload)
      dialog.querySelector('.modal-description').textContent = data.message
      dialog.querySelector('.modal-title').textContent = data.title || 'Confirm'
      dialog.querySelector('.cancel').textContent = data.cancel_button_text || 'Cancel'
      $(dialog).find('button.confirm').text(data.confirm_button_text || 'OK')

      $(dialog).find('.icon, button.success, button.alert').addClass('hidden')
      if (data.type == 'alert' || data.type == undefined ) {
        dialog.querySelector('.alert').classList.remove('hidden')
        dialog.querySelector('button.alert').classList.remove('hidden')
      }
      else if (data.type == 'success') {
        dialog.querySelector('.success').classList.remove('hidden')
        dialog.querySelector('button.success').classList.remove('hidden')
      }

      dialog.showModal()

      return new Promise((resolve) => {
        dialog.addEventListener('close', () => {
          resolve(dialog.returnValue == 'ok')
        }, { once: true })

        turbo_close.addEventListener('click', () => {
          dialog.close()
        })
      })
    })
  }
}
