window.module 'Booksession', ->
  @module 'External', ->
    @module 'GiftCards', ->
      @module 'Form', ->
        @init = ->
          $('input[type=radio]').change ->
            $('label.amount').removeClass('border-indigo-500 bg-indigo-500 text-white').addClass('text-gray-500 bg-white hover:bg-gray-50')
            $(@).closest('label').addClass('border-indigo-500 bg-indigo-500 text-white').removeClass('text-gray-500 bg-white hover:bg-gray-50')

          $('input[type=radio]').trigger 'change' if $('input[type=radio]:checked').length

          $('input#gift_card_record_to_myself').change ->
            $('#to_not_me').toggleClass 'hidden', $(@).is(':checked')
            $('#to_not_me').find('input').val('') unless $(@).is(':checked')


          $("#gift_card_record").validate
            ignore: []
            errorPlacement: (error, element) ->
              error.addClass 'text-red-600 mt-2'

              if element.is(':radio')
                error.insertAfter element.parents('.mt-6.grid')
              else
                error.insertAfter element

            rules:
              'gift_card_record[gift_card_amount_id]':
                required: true
              'gift_card_record[from_name]':
                required: true
              'gift_card_record[from_email]':
                required: true
                email: true
              'gift_card_record[to_name]':
                required: ->
                  toNotMe()
              'gift_card_record[to_email]':
                required: ->
                  toNotMe()
                email: true
              'gift_card_record[message]':
                maxlength: 65536

        toNotMe =->
          $('#gift_card_record_to_myself').is(':checked') == false



$(document).on "turbo:load", ->
  Booksession.External.GiftCards.Form.init() if $('body.external.gift-card-records').length
