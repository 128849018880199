window.module('Booksession', function() {
  return this.module('PayWithPaypal', function() {
    return this.init = function() {

      const self = $('#paypal-button-container')

      paypal.Buttons({
        env: gon.paypal_mode, // Valid values are sandbox and live.
        locale: 'en_US',
        style: {
          color: 'gold',
          shape: 'pill',
          size: 'medium',
          label: 'pay'
        },
        createOrder: async () => {
          $('#paypal-button-container, svg.paypal-animate').toggleClass('hidden')
          const response = await fetch(self.data('createOrder'), { method: 'POST' });
          const responseData = await response.json();
          if (responseData.token != 'error') {
            return responseData.token;
          }
          else {
            $('#paypal-button-container, svg.paypal-animate').toggleClass('hidden')
          }
        },
        onApprove: async (data) => {
          const response = await fetch(self.data('captureOrder'), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ order_id: data.orderID })
          });
          const responseData = await response.json();
          if (responseData.status === 'COMPLETED') {
            window.location.href = responseData.success
          }
          else {
            $('#paypal-button-container, svg.paypal-animate').toggleClass('hidden')
          }
        }
      }).render('#paypal-button-container');

    };
  });
});

$(document).on("turbo:load", function() {
  if ($('#paypal-button-container').length) {
    return Booksession.PayWithPaypal.init();
  }
});
