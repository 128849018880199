import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['image']

  selectPhoto(e) {
    let parent = e.target.closest('li')

    if (parent.classList.contains('selected')) {
      parent.classList.remove('selected')
    }
    else {
      parent.classList.add('selected')
    }
  }
}
