import { Controller } from '@hotwired/stimulus'
import {post} from '@rails/request.js'

export default class extends Controller {
  markAsSeen(e) {
    post(e.currentTarget.dataset.url, {
      responseKind: "json"
    })
  }
}
