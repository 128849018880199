import { Controller } from "stimulus";
import "../src/libs/combodate.js"; // Ensure combodate is available in your project

export default class extends Controller {
  connect() {
    $(this.element).combodate({
      firstItem: 'name',
      minuteStep: 1,
      customClass: ''
    });
  }
}
