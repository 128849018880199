// controllers/watermarks_mobile_view_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['aside'] // Target the aside element

  connect() {
    this.updateView() // Set the initial state
    window.addEventListener('resize', this.updateView.bind(this)) // Listen for resize events
  }

  disconnect() {
    window.removeEventListener('resize', this.updateView.bind(this)) // Clean up on disconnect
  }

  updateView() {
    const isMobile = window.innerWidth <= 640; // Check if the viewport width is less than or equal to 640px

    if (isMobile) {
      this.element.classList.remove('flex') // Remove flex class for mobile view
      this.asideTarget.classList.remove('w-80') // Remove w-80 class from aside
    } else {
      this.element.classList.add('flex') // Add flex class for normal view
      this.asideTarget.classList.add('w-80') // Add w-80 class back to aside
    }
  }
}
