window.module 'Booksession', ->
  @module 'OfflineJS', ->
    @init =->
      Offline.options =
        checks:
          xhr:
            url: ->
              return "https://booksession-proxy.fly.dev/https://1.1.1.1?_=#{new Date().getTime()}"

      if Offline.state == 'up'
        setInterval (->
          Offline.check()
          return
        ), 3000

$(document).on "turbo:load", ->
  Booksession.OfflineJS.init()
