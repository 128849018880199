import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggleTemplates(e) {
    let template_link = document.getElementById('templates_submenu_link')
    let settings_link = document.getElementById('settings_submenu_link')

    settings_link.classList.remove('bg-indigo-800')
    document.getElementById('settings_submenu').classList.add('hidden')

    document.getElementById('templates_submenu').classList.toggle('hidden')

    if (template_link.classList.contains('active') == false) {
      template_link.classList.toggle('bg-indigo-800')
    }
  }

  toggleSettings(e) {
    let template_link = document.getElementById('templates_submenu_link')
    let settings_link = document.getElementById('settings_submenu_link')
    document.getElementById('templates_submenu').classList.add('hidden')
    template_link.classList.remove('bg-indigo-800')

    document.getElementById('settings_submenu').classList.toggle('hidden')

    if (settings_link.classList.contains('active') == false) {
      settings_link.classList.toggle('bg-indigo-800')
    }
  }
}
