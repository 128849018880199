import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = [
    "date",
    "spotsInput",
    "startTime",
    "duration",
    "gap",
    "spotsList",
    "addToFormButton",
    "timeslotsContainer" // Add a new target for the container to insert HTML
  ];

  connect() {
    // Attach event listeners to date and start time fields to listen for custom events
    this.dateTarget.addEventListener("dateChanged", this.generateTimeslots.bind(this));
    this.startTimeTarget.addEventListener("timeChanged", this.generateTimeslots.bind(this));

    // Attach event listeners for other input fields
    this.spotsInputTarget.addEventListener("change", () => this.generateTimeslots(false));
    this.durationTarget.addEventListener("change", () => this.generateTimeslots(false));
    this.gapTarget.addEventListener("change", () => this.generateTimeslots(false));

    this.addToFormButtonTarget.addEventListener("click", () => {
      this.generateTimeslots(true);
    });

    // Generate timeslots initially when the page loads
    this.generateTimeslots(false);
  }

  generateTimeslots(addToForm = false) {
    const date = this.dateTarget.value;
    const spots = Number(this.spotsInputTarget.value);
    const startTime = this.startTimeTarget.value;
    const duration = Number(this.durationTarget.value);
    const gap = Number(this.gapTarget.value);

    // Validate date format
    if (!moment(date, "DD-MM-YYYY", true).isValid()) {
      alert("Date is invalid");
      return;
    }

    // Validate time format
    if (!moment(startTime, "HH:mm", true).isValid()) {
      alert("Start Time is invalid");
      return;
    }

    if (spots <= 0) {
      alert("Spots should be a positive number");
      return;
    }

    if (duration <= 0) {
      alert("Duration should be a positive number");
      return;
    }

    if (gap < 0) {
      alert("Gap cannot be negative");
      return;
    }

    // Clear the existing preview list
    if (!addToForm) {
      this.spotsListTarget.innerHTML = "";
    }

    let initDateTime = moment(`${date} ${startTime}`, "DD-MM-YYYY HH:mm");

    // Generate timeslots
    for (let i = 0; i < spots; i++) {
      if (addToForm) {
        // Insert timeslot HTML into the form
        this.insertTimeslotHtml(initDateTime.format("DD-MM-YYYY HH:mm"), duration);
      } else {
        // Display the timeslots in the list for preview
        const listItem = document.createElement("li");
        listItem.classList.add("border-0", "border-b", "border-solid", "py-2");
        listItem.innerHTML = `<p class='text-sm text-gray-600 line-clamp-2'>${initDateTime.format(
            "DD-MM-YYYY HH:mm"
        )}</p>`;
        this.spotsListTarget.appendChild(listItem);
      }
      // Increment time by duration and gap
      initDateTime.add(duration + gap, "minutes");
    }
  }

  // Method to insert timeslot HTML into the form
  insertTimeslotHtml(startTime, duration) {
    const id = Math.floor(Math.random() * Date.now());
    const timeslotHtml = `
    <div class="nested-fields mt-6">
      <div class="shadow sm:rounded-md mb-6 border">
        <div class="px-4 py-2 bg-white sm:p-3">
          <div class="grid grid-cols-6 gap-6">
            
            <!-- Starts At Field -->
            <div class="col-span-4 sm:col-span-2">
              <label for="booking_template_timeslots_attributes_${id}_starts_at" class="block text-sm font-medium text-gray-700">Starts at</label>
              <div class="flex rounded-md shadow-sm">
                <span class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  <svg  class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                  </svg>
                </span>
                <input value="${startTime}" data-controller="flatpickr" data-type="datetime" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" type="text" name="booking_template[timeslots_attributes][${id}][starts_at]" id="booking_template_timeslots_attributes_${id}_starts_at">
              </div>
            </div>

            <!-- Duration Field -->
            <div class="col-span-4 sm:col-span-2">
              <label for="booking_template_timeslots_attributes_${id}_duration" class="block text-sm font-medium leading-5 text-gray-700">Duration</label>
              <div class="mt-1 flex rounded-md shadow-sm">
                <input type="number" value="${duration}" name="booking_template[timeslots_attributes][${id}][duration]" id="booking_template_timeslots_attributes_${id}_duration" class="form-input flex-1 block w-full rounded-none rounded-l-md transition duration-150 ease-in-out sm:text-sm sm:leading-5 w-12">
                <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">minutes</span>
              </div>
            </div>

            <!-- Remove Button -->
            <div class="col-span-4 sm:col-span-2 py-6">
              <span class="inline-flex">
                <a href="#" data-confirm="Are you sure?" class="inline-flex items-center justify-center py-2 font-medium rounded-md text-red-700 focus:outline-none focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 remove_fields existing">
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                  </svg>
                </a>
              </span>
            </div>
          </div>

          <!-- Visibility and Booking Checkboxes -->
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-4 sm:col-span-2">
              <div class="flex items-start mt-3">
                <div class="flex items-center h-5">
                  <input type="checkbox" name="booking_template[timeslots_attributes][${id}][visible]" id="booking_template_timeslots_attributes_${id}_visible" checked="checked" class="switch">
                </div>
                <div class="ml-3 text-sm leading-5">
                  <label for="booking_template_timeslots_attributes_${id}_visible" class="font-medium text-gray-700">Visible for booking</label>
                </div>
              </div>
            </div>

            <div class="col-span-4 sm:col-span-2">
              <div class="flex items-start mt-3">
                <div class="flex items-center h-5">
                  <input type="checkbox" name="booking_template[timeslots_attributes][${id}][mark_as_booked]" id="booking_template_timeslots_attributes_${id}_mark_as_booked" class="switch">
                </div>
                <div class="ml-3 text-sm leading-5">
                  <label for="booking_template_timeslots_attributes_${id}_mark_as_booked" class="font-medium text-gray-700">Mark as booked</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`;

    // Insert the timeslot HTML before the .links element in the timeslotsContainer
    this.timeslotsContainerTarget.insertAdjacentHTML("beforebegin", timeslotHtml);
    document.getElementsByClassName('modal-close')[0].click()
  }
}
