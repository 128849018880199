window.module 'Booksession', ->
  @module 'Modals', ->
    @init =->
      initCloseModal()
      initOpenModal()

    initOpenModal =->
      $(document).on 'click', 'a.open-modal, button.open-modal', (e) ->
        e.preventDefault()
        self = $(@)

        $(self.data('modal')).removeClass 'hidden'


    initCloseModal =->
      $(document).on 'onkeydown', (evt) ->
        evt = evt || window.event
        isEscape = false
        if 'key' of evt
          isEscape = evt.key == 'Escape' or evt.key == 'Esc'
        else
          isEscape = evt.keyCode == 27
        $('.modal-close').trigger 'click' if isEscape

      $(document).on 'click', '.modal-close', (e) ->
        e.preventDefault()
        $(@).closest('.modal').addClass 'hidden'


$(document).on "turbo:load", ->
  Booksession.Modals.init()
