import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  search(e) {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      let url = new URL(window.location.href);
      url.searchParams.set('search', e.target.value);

      get(url, {
        responseKind: "turbo-stream"
      }).then(() => {
        history.pushState({ turbolinks: true }, '', url)
      })
    }, 200)
  }
}
