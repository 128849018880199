import ColorPicker from '@stimulus-components/color-picker'

export default class extends ColorPicker {
  connect() {
    super.connect()
    // Pickr instance
    this.picker
  }

  // Callback when the color is saved
  onSave(color) {
    super.onSave(color)

    // Trigger handleChange in the TurboPutController with the field name 'color'
    this.triggerTurboPutChange('color', color.toHEXA().toString())
  }

  // Method to trigger the Turbo-put controller's handleChange method
  triggerTurboPutChange(fieldName, color) {
    const turboPutController = this.application.getControllerForElementAndIdentifier(this.element, "turbo-put")
    if (turboPutController) {
      turboPutController.handleChange({ target: { name: fieldName, value: color.replace("#", '') } })
    } else {
      console.error("Turbo-put controller not found on this element")
    }
  }

  get componentOptions() {
    return {
      preview: true,
      hue: true,
      interaction: {
        input: true,
        clear: true,
        save: true,
      },
    }
  }

  get swatches() {
    return [
      '#A0AEC0',
      '#F56565',
      '#ED8936',
      '#ECC94B',
      '#48BB78',
      '#38B2AC',
      '#4299E1',
      '#667EEA',
      '#9F7AEA',
      '#ED64A6',
    ]
  }
}
