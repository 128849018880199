window.module 'Booksession', ->
  @module 'Template', ->
    @module 'Builder', ->
      @init = ->
        initDraggableFields()
        initDroppable()
        initDeleteField()
        initCloneField()
        initUpdateTC()

        Booksession.Template.ChildField.init()

        if $(".js-my-affix").length
          $(".js-my-affix").affix
            offset:
              top: $(".js-my-affix").offset().top

      initDraggableFields =->
        $(".available_fields .drag-item").draggable
          helper: 'clone'
          scroll: true
          revert: 'invalid'
          cursor: 'move'
          connectToSortable: '.fields'

      initDroppable =->
        $('.fields').sortable
          placeholder: 'ui-state-highlight'
          handle: 'span.sortable-handler'
          update: (event, ui) ->
            calcFieldPositionNumber()
          receive: (event, ui) ->
            if ui.item.data('preview')

              id = Math.floor(Math.random() * Date.now())
              canvas_id = Math.random().toString(36).substring(7)

              form_group = $("#{ ui.item.data('preview').replace(/REPLACE_ID/g, id).replace(/REPLACE_CANVAS_ID/g, canvas_id ) }")

              $(@).find('.drag-item').replaceWith form_group

              setTimeout (->
                updateFieldNames(form_group)
                calcFieldPositionNumber()
              ), 0


      calcFieldPositionNumber = ->
        $('.form-group').each ->
          index = $($(@).closest('.fields').find('.form-group')).index $(@)
          $(@).find('input.position').val(index + 1)


      updateFieldNames = (form_group) ->
        timestamp = new Date().getTime()

        form_group.find('input:not(".preview"), textarea').each ->
          return if $(@).closest('.settings').length

          new_name = "#{gon.template_name}[template_fields_attributes][#{ timestamp }][#{ $(@).prop('name') }]"
          updateNameAndId $(@), new_name, nameToId(new_name)


      updateNameAndId = (elem, name, id) ->
        elem[0].setAttribute('name', name)
        elem.prop('id', id)
        elem.closest('.col-span-6').find('label').prop('for', id)

      nameToId = (name) ->
        name.replace(/\]\[|\[|\]/g, '_').slice(0, -1)


      initDeleteField =->
        $(document.body).on 'click', 'button.destroy-field', (e) ->
          e.preventDefault()
          self = $(@)

          if confirm("Deleting this field will PERMANENTLY delete it from the system and it won't be available anymore.")
            destroy_field = self.closest('.form-group').find('input.field-destroy').val('true').clone()

            self.closest('form').prepend destroy_field

            #Remove field
            self.closest('.form-group').remove()

            calcFieldPositionNumber()


      initCloneField =->
        $(document.body).on 'click', 'button.clone-field', (e) ->
          e.preventDefault()
          self = $(@)

          if confirm('Duplicating this field will create a copy of it.')
            cloneField self.closest('.form-group')

      initUpdateTC =->
        $('body').on 'keyup', 'input.tc-field', (e) ->
          self = $(@)
          $(@).closest('.form-group').find('label.tc-label').html self.val()


      cloneField = (form_group) ->
        id = Math.floor(Math.random() * Date.now())
        canvas_id = Math.random().toString(36).substring(7)

        #========================================= Preview ===========================================
        #copy preview section
        #let's find preview section
        new_section_html = form_group.clone()

        #rename all fields in preview section
        new_section_html.find('input, textarea').each ->
          $(@).prop "name", $(@).prop('name').split("][").pop().replace(']', '') if $(@).prop('name')
          $(@).removeProp 'id'

        #change id and class
        new_section_html.prop('id', id)

        $('.fields').append new_section_html

        #remove id field
        new_section_html.find('[type=\"hidden"][name=\"id"]').remove()

        setTimeout (->
          updateFieldNames(new_section_html)
          calcFieldPositionNumber()
        ), 0


$(document).on "turbo:load", ->
  Booksession.Template.Builder.init() if $('body.booking-templates, body.questionnaire-templates').find('form').length
