import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

export default class extends Controller {
  static targets = [ "window" ]
  calendar = null
  currentView = null

  connect() {
    this.initCalendar()
    this.populateButtons()
    this.initFilters()

    window.addEventListener('load', () => {
      this.calendar.render()
    })

    window.addEventListener('fullCalenadar:fecthEvents', () => {
      this.calendar.refetchEvents()
    })
  }

  disconnect() {
    this.calendar.destroy()
  }

  initCalendar() {
    this.calendar = new Calendar(this.windowTarget, {
      plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
      initialView: 'dayGridMonth',
      eventDisplay: 'block',
      displayEventEnd: true,
      firstDay: 1,
      height: this.calculateRemainingHeight(),
      headerToolbar: false,
      locale: 'en-au',
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        meridiem: false,
        hour12: false
      },
      eventSources: [
        {
          url: '/api/events/calendar.json',
          extraParams: function() {
            return {
              cachebuster: new Date().valueOf(),
            }
          }
        },
        {
          url: '/api/nonavailabilities.json',
          extraParams: function() {
            return {
              cachebuster: new Date().valueOf(),
            }
          }
        },
        {
          url: '/api/availabilities.json',
          extraParams: function() {
            return {
              cachebuster: new Date().valueOf(),
            }
          }
        }
      ],
      datesSet: () => {
        this.postRender()
      },
      eventDidMount: function(info) {
        const event = info.event
        const element = info.el

        element.style.backgroundColor = event.backgroundColor
        element.style.borderColor = event.borderColor
        element.style.color = event.textColor
        Object.entries(event.extendedProps.data).forEach(([key, value]) => {
          element.setAttribute(`data-${key}`, value)
        })
      }
    });
  }

  postRender() {
    this.currentView = this.calendar.view.type

    const calendarDate = document.getElementById('calendar_date')
    calendarDate.innerHTML = this.calendar.view.title
  }

  populateButtons() {
    const prevButton = document.getElementById('calendar_prev');
    const nextButton = document.getElementById('calendar_next');
    const todayButton = document.getElementById('calendar_today');
    const todayButtonMobile = document.getElementById('calendar_today_mobile');

    prevButton?.addEventListener('click', () => this.calendar.prev());
    nextButton?.addEventListener('click', () => this.calendar.next());
    todayButton?.addEventListener('click', () => this.calendar.today());
    todayButtonMobile?.addEventListener('click', () => this.calendar.today());
  }

  calculateRemainingHeight() {
    const remainingHeight = window.innerHeight - this.element.getBoundingClientRect().top;
    return remainingHeight - 40;
  }

  initFilters() {
    let views = {
      "timeGridDay": "Day view",
      "timeGridWeek": "Week view",
      "dayGridMonth": "Month view"
    }

    const changeView = (viewType, ...elementIds) => {
      elementIds.forEach(id => {
        const element = document.getElementById(id);
        element?.addEventListener('click', () => {
          this.calendar.changeView(viewType)
          document.getElementById('view_type').innerHTML = views[viewType]
        })
      });
    };

    changeView('timeGridDay', 'day_view', 'day_view_mobile');
    changeView('timeGridWeek', 'week_view', 'week_view_mobile');
    changeView('dayGridMonth', 'month_view', 'month_view_mobile');
  }

  // refresh(e) {
  //   if (e.detail.success) {
  //     this.calendar.refetchEvents();
  //   }
  // }
}
